interface GpcResponse {
  error: boolean;
  show_gpc?: boolean;
  message?: string;
}

interface NavigatorGPC extends Navigator {
  globalPrivacyControl?: boolean;
}

export const getTargetHostname = ({
  hostname,
  protocol,
}: {
  hostname: string;
  protocol: string;
}): string => {
  if (
    [
      '.maxmind.com',
      '.blog-site-602.pages.dev',
      '.dev-site-4ua.pages.dev',
    ].some((s) => hostname.endsWith(s)) ||
    `${protocol}//${hostname}` === 'https://maxmind.zendesk.com'
  ) {
    return 'www.maxmind.com';
  }
  return hostname;
};

const targetHostname = getTargetHostname(window.location);

const gpcCheck = async (signal: boolean): Promise<boolean> => {
  let showGpc: boolean;

  if (window.location.pathname.startsWith('/en/opt-out')) {
    showGpc = false;
  } else {
    try {
      const destinationUrl = new URL(
        `${window.location.protocol}//${targetHostname}/en/gpc-check`,
      );
      destinationUrl.searchParams.append('js_signal', signal ? '1' : '0');
      const response = await fetch(destinationUrl, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      const gpcBody: GpcResponse = await response.json();
      showGpc = gpcBody.show_gpc ?? false;
    } catch (_error) {
      showGpc = false;
    }
  }
  return showGpc;
};

// This works around the behavior of safari in private mode
const getHasLocalStorage = (): boolean => {
  try {
    const testString = '_mm-ls-test';
    window.localStorage.setItem(testString, testString);
    const testGet = window.localStorage.getItem(testString);
    window.localStorage.removeItem(testString);
    return testGet === testString;
  } catch (_error) {
    return false;
  }
};

const hasLocalStorage = getHasLocalStorage();

const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000;

const gpcTimestampKey = '_mmgpctime';

const styles = `
dialog#global-privacy-control-check::backdrop { background-color: rgba(0,0,0,0.75); }
dialog#global-privacy-control-check {
    border-color: rgba(0, 0, 0, 0.175);
    border-radius: 7px;
    border-width: 1px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    color: #364956;
    max-width: 95%;
    padding: 0px;
}
p.gpc-modal-p {
    padding: 16px;
    padding-bottom: 0px;
}
div.gpc-modal-header {
    align-items: flex-end;
    background-color: rgb(248, 249, 250);
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
    display: flex;
    justify-content: space-between;
    padding: 16px;
}
div.gpc-modal-header h4 {
    margin: 0;
}
div.gpc-modal-header button {
    border: 1px solid rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    font-size: 1.2rem;
    padding: 1px 12px;
    transition: box-shadow, background-color 0.15s ease;
}
div.gpc-modal-header button:active,
div.gpc-modal-header button:hover {
    background-color: rgba(0,0,0,0.15);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
}
div.gpc-modal-footer {
    background-color: rgb(248, 249, 250);
    border-top: 1px solid rgba(0, 0, 0, 0.175);
    display: flex;
    justify-content: flex-end;
    padding: 6px 12px;
}
div.gpc-modal-footer button {
    background: #008fc6;
    border-radius: 0;
    border: 1px solid #008fc6;
    color: #fdfbdc;
    margin: 0.5rem;
    padding: 0.375rem 1.125rem;
    transition: color, background-color 0.15s ease;
}
div.gpc-modal-footer button:active {
    background-color: #0a58ca;
    border-color: #0a53be;
    color: #fff;
}
div.gpc-modal-footer button:focus, div.gpc-modal-footer button:hover {
    background-color: #006a93;
    border-color: #006a93;
    color: #fdfbdc;
}
@media screen and (min-width: 530px) {
    dialog#global-privacy-control-check {
        max-width: 500px;
    }
}
`;

// returns true if modal has been dismissed in the past 30 days
const hasRecentlyDismissedModal = (): boolean => {
  if (!hasLocalStorage) return false;
  const dismissalTime = Number(window.localStorage.getItem(gpcTimestampKey));
  return Date.now() - dismissalTime < oneMonthInMilliseconds;
};

const storeModalDismissal = () => {
  if (!hasLocalStorage) return;
  window.localStorage.setItem(gpcTimestampKey, Date.now().toString());
};

const runGpc = async () => {
  const showGpc = await gpcCheck(
    (navigator as NavigatorGPC).globalPrivacyControl ?? false,
  );
  if (!showGpc || hasRecentlyDismissedModal()) return;
  // create modal
  const modal = createModal(targetHostname);
  // creates styles
  const sheet = new CSSStyleSheet();
  sheet.replaceSync(styles);
  // add to DOM
  document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
  document.body.appendChild(modal);
  // register event listeners
  const buttons = modal.querySelectorAll('button');
  buttons.forEach((button) =>
    button.addEventListener('click', () => {
      modal.close();
    }),
  );
  const doNotSellAnchor = document.querySelector('a.js-donotsell');
  if (doNotSellAnchor)
    doNotSellAnchor.addEventListener('click', () => {
      modal.close();
    });
  modal.addEventListener('close', () => {
    storeModalDismissal();
  });
  // prevent ESC-ing out of modal
  modal.addEventListener('cancel', (e) => e.preventDefault());
  modal.showModal();
};

function createModal(hostname: string) {
  const modal = document.createElement('dialog');
  modal.id = 'global-privacy-control-check';

  // Header
  const header = document.createElement('div');
  header.className = 'gpc-modal-header';

  const title = document.createElement('h4');
  title.textContent = 'Global Privacy Control';

  const closeButton = document.createElement('button');
  closeButton.className = 'btn-close';
  closeButton.type = 'button';
  closeButton.setAttribute('aria-label', 'Close');

  header.append(title, closeButton);

  // Body
  const p1 = document.createElement('p');
  p1.className = 'gpc-modal-p';
  p1.textContent =
    'MaxMind has detected a GPC signal from your browser. MaxMind does not share any data collected from browsers with the GPC signal with any other companies (except with our service providers or as required by law).';

  const p2 = document.createElement('p');
  p2.className = 'gpc-modal-p';
  p2.textContent =
    'If you would like to opt out of other sharing of your personal data please see our ';

  const link = document.createElement('a');
  link.className = 'js-donotsell';
  link.href = `${window.location.protocol}//${hostname}/en/opt-out`;
  link.rel = 'noreferrer noopener';
  link.target = '_blank';
  link.textContent = 'Do Not Sell Webform';

  p2.appendChild(link);
  p2.appendChild(document.createTextNode('.'));

  // Footer
  const footer = document.createElement('div');
  footer.className = 'gpc-modal-footer';

  const footerButton = document.createElement('button');
  footerButton.type = 'button';
  footerButton.textContent = 'Close';

  footer.appendChild(footerButton);

  modal.append(header, p1, p2, footer);

  return modal;
}

runGpc();
